<template>
  <!-- 我的消息 公告 -->
  <app-container :title="'我的消息'" :background="tool.getThemeList(theme)">
    <div class="message_notice">
      <div class="left_notice">{{ item.title }}</div>
      <div class="date">
        {{ item.sendTime ? item.sendTime : item.publishTime }}
      </div>
      <div class="text" v-html="item.content"></div>
    </div>
    <div class="return_button" @click="lastPage">返回</div>
  </app-container>
</template>

<script>
import { useRoute } from "vue-router";
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
export default {
  setup() {
    const state = reactive({
      item: "", //内容
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    state.item = route.query;
    const methods = {
      //返回通知列表页
      lastPage() {
        window.history.back();
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.message_notice {
  width: 690px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 0px;
  .left_notice,
  .date,
  .text {
    margin-top: 10px;
    padding: 0px 40px;
  }
  .date {
    color: #aaaaaa;
  }
}
.return_button {
  width: 690px;
  height: 84px;
  color: #ffffff;
  @include background_color("background_color1");
  box-shadow: 0px 8px 16px 0px #bce1ff;
  border-radius: 20px;
  margin: 40px auto 0px;
  text-align: center;
  line-height: 84px;
}
.van-nav-bar {
  @include background_color("background_color1");
  /deep/.van-nav-bar__left {
    .van-icon {
      color: #ffffff;
      font-size: 48px;
    }
  }
  /deep/ .van-nav-bar__title {
    font: {
      size: 30px;
      weight: 500;
    }
    color: #ffffff;
  }
}

.van-hairline--bottom::after {
  border: none;
}
</style>
